import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser'
import Typography from '@mui/material/Typography'
import { HOME, TEAM, TEAMS } from '../../_constants/routes'
import PersonalityGraph from '../_library/PersonalityGraph'
import TeamMateProfile from './TeamMateProfile'
import { useNavigate } from 'react-router-dom'
import { ACCESS_LEVEL_MANAGER, CARD_COLLECTION, CARD_TEMPLATE_COLLECTION, TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import demoTeam from './demo-team.json'
import StickyScrollBtn from '../_library/StickyScrollBtn'
import CTAButton from '../_library/FirstLevelButton'
import { ArrowDownward } from '@mui/icons-material'
import IOSSwitch from '../_library/IOSSwitch'
import { getTeamBadge, getTeamProfileType } from '../../_helpers/cardHelper'
import CardHeader from './CardHeader'
import { TEAM_PROFILE } from '../../_constants/cardTypes'
import { Box, Link } from '@mui/material'
import Page from '../_library/Page'
import { useScroll } from 'framer-motion'
import { SELECT_USER_ID } from '../../store/types'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'
import { arrayUnion } from 'firebase/firestore'
import { useTranslation } from 'react-i18next'


const containerStyle = {
  my: 0,
  mx: { xs: 1, sm: 'auto' },
  pl: { xs: 0, sm: '11%' },
  textAlign: 'left',
  width: { md: '60vw' },
  maxWidth: { xs: '100%', md: 700 },
  position: 'relative',
  color: 'black',
  fontSize: '0.9rem',
  lineHeight: '2rem',
  '& h3': {
    color: 'tertiary.dark',
    mb: '0.4rem',
    fontSize: '1.4rem',
    fontWeight: 800,
    mt: '2rem',
  },
  '& ul': {
    paddingInlineStart: '18px',
    ml: 0,
  },
  '& li': {
    fontSize: '1rem',
  },
}

const articleStyle = {
  position: 'relative',
  '& > img': {
    position: { md: 'absolute' },
    top: { md: 0 },
    left: { md: -300 },
    width: { xs: 200, md: 300 },
    display: { xs: 'block' },
    margin: { xs: '0 auto -2rem' },
  },
}

const TeamCardPage = ({ demo }) => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const profile = useAuth().getProfile()
  const cardHooks = useFirestore(CARD_COLLECTION)
  const cards = cardHooks.getDocs()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const templateHooks = useFirestore(CARD_TEMPLATE_COLLECTION)
  const dbTemplates = templateHooks.getDocs()
  const consultantMode = useSelector(state => state.data.consultantMode)
  const pageContentRef = useRef(null)
  const { scrollYProgress } = useScroll({ container: pageContentRef })
  const selectedUserId = useSelector(state => state.data.selectedUserId)
  const { t, i18n } = useTranslation()
  
  const [filteredMembers, setFilteredMembers] = useState(null)
  const [selectedMembers, setSelectedMembers] = useState(null)
  // const [memberProfileId, setMemberProfileId] = useState(null)
  const [scrollProgress, setScrollProgress] = useState(null)
  
  const team = useMemo(() => dbTeams?.find(t => t.id === profile.currentTeamSelectedRef?.id) || {
    name: 'soloTeam',
    memberRefs: [{ id: profile.id }],
  }, [dbTeams, profile])
  const teamProfile = useMemo(() => dbTemplates?.find(t => t.type === 'team-profile'), [dbTemplates])
  const teamMembers = useMemo(
    () => dbUsers?.filter(u => u.id === profile.id || u.teamRefs?.some(t => t.id === profile.currentTeamSelectedRef?.id)),
    [dbUsers],
  )
  
  useEffect(() => {
    if (demo)
      setFilteredMembers(demoTeam.members)
    else if (consultantMode)
      setFilteredMembers(teamMembers?.filter(u => !!u.testResults))
    else if (teamMembers && cards)
      setFilteredMembers(teamMembers.filter(element => cards.some(card => card.userRef.id === element.id && card.rating) && element.profileType))
  }, [teamMembers, cards, demo, profile, consultantMode])
  
  useEffect(() => { setSelectedMembers(filteredMembers) }, [filteredMembers])
  
  useEffect(() => {scrollYProgress.on('change', setScrollProgress)}, [scrollYProgress])
  
  const handleSelect = id => {
    setSelectedMembers(members => members.some(m => m.id === id)
      ? members.filter(m => m.id !== id)
      : [...members, filteredMembers.find(m => m.id === id)],
    )
  }
  
  const readTeamProfileAndNextPage = () => teamHooks.updateDoc(profile.currentTeamSelectedRef.id, {
    teamCardReadByUserRefs: arrayUnion(userHooks.getDocRef(profile.id)),
  })
  
  const title = useMemo(() => teamProfile?.title[i18n.language], [teamProfile])
  const subtitle = useMemo(() => teamProfile?.subtitle[i18n.language], [teamProfile])
  const teamProfileType = useMemo(() => getTeamProfileType(selectedMembers), [selectedMembers])
  const article = useMemo(() => {
    if (!teamProfile?.article || !teamProfile.article[teamProfileType]) return null
    const genderedArticle = teamProfile.article[teamProfileType][i18n.language][profile.gender]
    if (!genderedArticle) console.error(`Unable to cast article for cardTemplate ${teamProfile.id} with language ${i18n.language} and gender ${profile.gender}`)
    return parse(teamProfile.article[teamProfileType][i18n.language][profile.gender]
      .replace(/FIRSTNAME/g, profile.firstname)
      .replace('Le talent de votre équipe', t('team-title.' + teamProfileType)) // J'ai la flemme
      .replace('Your team’s talent', t('team-title.' + teamProfileType)), // Encore la flemme
    )
  }, [teamProfile, teamProfileType, profile])
  
  if (scrollProgress === null) {
    window.scrollTo(0, 0)
    setScrollProgress(0)
    if (team && profile.currentTeamSelectedRef && !!((!team.teamCardReadByUserRefs || (team.teamCardReadByUserRefs && !team.teamCardReadByUserRefs.some(element => element.id === profile.id)))))
      readTeamProfileAndNextPage()
  }
  
  return (
    <Page withTopBar>
      <Box sx={{ pb: '140px' }}>
        <CardHeader title={demo ? '(DEMO) ' + title : title} subtitle={subtitle} type={TEAM_PROFILE} />
        <Box sx={containerStyle}>
          <Typography fontSize='1.3rem' fontWeight={600} pt={4}>{t('dashboard.team-card.instructions')}</Typography>
          <Box sx={{
            position: { xs: 'static', md: 'absolute' },
            left: '-200px',
            padding: '0 45px',
            top: '250px',
            color: 'primary.main',
            maxWidth: 300,
            fontWeight: 700,
            maxHeight: 300,
            overflowY: 'auto',
          }}>
            <Box component='ul' sx={{ listStyleType: 'none' }}>
              {filteredMembers && filteredMembers.map(member =>
                <li key={member.id || profile.id}>
                  <IOSSwitch
                    checked={selectedMembers?.some(m => m.id === member.id) || false}
                    onChange={() => handleSelect(member.id || profile.id)}
                  />
                  <span>{member.firstname}</span>
                </li>,
              )}
            </Box>
            {profile.accessLevel >= ACCESS_LEVEL_MANAGER && !consultantMode && <Link onClick={() => navigate(demo ? TEAMS : TEAM)} sx={{
              fontSize: '1rem',
              textDecoration: 'none',
              color: 'grey.200',
              fontWeight: 700,
              mt: 20,
              '& strong': {
                fontSize: '3rem',
                fontWeight: 100,
                verticalAlign: 'middle',
              },
            }}>
              <strong>+</strong>&nbsp;{t('dashboard.team-card.add-member')}
            </Link>}
          </Box>
          {selectedUserId && <TeamMateProfile
            userId={selectedUserId}
            onClick={() => dispatch({ type: SELECT_USER_ID, payload: null })}
            teamId={(profile.teamRefs && profile.currentTeamSelectedRef?.id) ?? null }
            demo={demo}
          />}
          {!selectedUserId && selectedMembers && <PersonalityGraph
            users={selectedMembers}
            onClickAvatar={id => dispatch({ type: SELECT_USER_ID, payload: id })}
            displayAvatars
            displayHeart
          >
            {filteredMembers?.length !== selectedMembers?.length && teamProfileType && <Box sx={{
              bgcolor: 'grey.100',
              color: 'primary.light',
              fontWeight: 600,
              width: 'fit-content',
              p: 1,
              mt: theme => `calc(-1rem - ${theme.spacing(2)})`,
              ml: '-50px',
              borderRadius: '3px',
              lineHeight: 1,
              fontSize: { xs: '.7rem', sm: '1rem' },
              '& svg': {
                fontSize: 'inherit',
                verticalAlign: 'bottom',
              },
            }}>{t('dashboard.team-card.new-description')} <ArrowDownward /></Box>}
          </PersonalityGraph>}
          {!selectedUserId && <Box sx={articleStyle}>
            {teamProfileType && <img src={getTeamBadge(teamProfileType)} alt='badge' />}
            {article}
          </Box>}
          {demo
            ? <CTAButton onClick={() => navigate(TEAMS)}>{t('dashboard.team-card.invite-teammates')}</CTAButton>
            : <StickyScrollBtn onClick={() => navigate(HOME)} pageScrollPercent={scrollProgress} disabled={consultantMode}>{t('dashboard.next-card')}</StickyScrollBtn>
          }
        </Box>
      </Box>
    </Page>
  )
}

TeamCardPage.propTypes = {
  demo: PropTypes.bool,
}

export default TeamCardPage
