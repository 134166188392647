import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography, FormControlLabel } from '@mui/material'
import GreenCheckbox from '../_library/GreenCheckbox'
import { useTranslation } from 'react-i18next'


export const Questions = ({ topic, questions, selectedQuestions, teammate, selectHandler }) => {
  
  const { t, i18n } = useTranslation()
  
  const topicLabel = useMemo(() => topic.text
    ? topic.text[i18n.language].replace('FIRSTNAME', teammate.firstname)
    : t('one-to-one.noname-category')
  , [topic, teammate.firstname, i18n.language])
  
  return <>
    <Typography variant='h2' mt={2} fontSize='1.35rem' color='primary.main'>{topicLabel}</Typography>
    {questions.map(question =>
      <FormControlLabel
        sx={{ mx: 0 }}
        key={question.id}
        control={<GreenCheckbox checked={selectedQuestions && selectedQuestions.some(selectedQuestion => question.id === selectedQuestion.id)} name='checkedG' />}
        onChange={event => selectHandler(event.target.checked, question)}
        label={<Typography lineHeight='1.5rem' my='.7rem'>{question?.title[i18n.language]}</Typography>}
      />,
    )}
  </>
}

Questions.propTypes = {
  topic: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  selectedQuestions: PropTypes.array.isRequired,
  teammate: PropTypes.object.isRequired,
  selectHandler: PropTypes.func.isRequired,
}

export default Questions
